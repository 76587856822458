.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 3vh;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.labelContainer label {
  color: #2e2e2e;
  font-weight: 500;
}

.hintIconContainer {
  display: flex;
  justify-content: center;
}

.inputContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
}

.currency {
  position: absolute;
  top: 6px;
}

.inputContainer input {
  border: 0;
  border-bottom: 1px solid var(--input-default-border-color);
  padding: 0.4rem;
  background-color: transparent;
  color: #2e2e2e;
  width: 100%;
}

.inputContainer input:focus {
  outline: 0;
  border-bottom: 2px solid var(--primary-color);
}

.inputContainer input:disabled {
  color: rgb(138, 138, 138) !important;
  background-color: #2e2e2e0a !important;
}

.searchIconContainer {
  display: flex;
  align-items: center;
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}
