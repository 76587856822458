:root {
  --primary-color: #189ab4;
  --box-background-color: #f5f5f5;
  --box-border-color: #b4b2b275;
  --input-default-border-color: #bbb;
  --title-color: #1b1b1b;
}

body {
  overflow-x: hidden;
  background-color: var(--primary-color) !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace !important;
}

a {
  cursor: pointer;
}

p {
  color: #2e2e2e;
}

li {
  color: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--title-color) !important;
}

header {
  /* padding-top: 10px !important; */
  background-color: var(--box-background-color);
  border: 1px solid var(--box-border-color);
  border-radius: 4px;
  padding: 1vh 2vw;
  max-width: 600px;
  width: 96%;
}

header p {
  font-size: 0.9rem;
  margin: 12px 0;
}

section {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
  background-color: var(--box-background-color);
  border-radius: 4px;
  padding: 3vh 2vw;
  border: 1px solid var(--box-border-color);
  max-width: 600px;
  width: 96%;
}

section h4 {
  margin-bottom: 14px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
