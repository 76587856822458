.fieldSections {
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 500px) {
  .fieldSections {
    display: block;
    gap: 0px;
  }
}
