.container {
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 2vh 0;
  align-items: center;
  width: 100vw;
}

.container footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
}

.container form {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.container footer {
  display: flex;
  width: 96%;
  max-width: 600px;
}

@media screen and (max-width: 600px) {
  .container footer button {
    width: 100%;
  }
}
