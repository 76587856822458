.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 3vh;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.labelContainer label {
  color: #2e2e2e;
  font-weight: 500;
}

.hintIconContainer {
  display: flex;
  justify-content: center;
}

.selectContainer,
.selectContainerError {
  display: flex;
  flex-direction: row;
  margin-top: 0.3rem;
}

.selectContainer > div,
.selectContainerError > div {
  width: 100%;
}

.selectContainerError > div > div {
  border-color: red !important;
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}
