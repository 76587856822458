.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 3vh;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.labelContainer label {
  color: #2e2e2e;
  font-weight: 500;
}

.hintIconContainer {
  display: flex;
  justify-content: center;
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  row-gap: 10px;
}

.item {
  display: flex;
  align-items: center;
  margin-left: 14px;
}

.item input {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.itemLabelContainer {
  display: flex;
  min-width: 70px;
  align-items: center;
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}
