.formContainer {
  display: flex;
  flex-direction: column;
}

.button {
  align-self: flex-end;
  background-color: var(--primary-color) !important;
  color: white !important;
}

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  padding-top: 8px;
}

.checkbox input {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.footer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer .checkbox {
  margin: 0;
  padding: 0;
}
