.inputContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-bottom: 3vh;
}

.inputContainer label {
  color: #2e2e2e;
  font-weight: 500;
}

.inputContainer input[type="file"] {
  /* border: 1px solid var(--input-default-border-color); */
  padding: 0.4rem;
  background-color: transparent;
  color: #2e2e2e;
  display: none;
}

.inputContainer .buttonWrap {
  padding-top: 12px;
  position: relative;
}

.inputContainer .button,
.inputContainer .buttonWithAttachment,
.inputContainer .buttonError {
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  background-color: #1d8dbd2a;
  border: 1px dashed #1d8dbd;
  padding: 30px;
  height: 80px;
  width: 80px;
}

.inputContainer .buttonWithAttachment {
  padding: 0;
  height: 80px;
  width: 80px;
}

.inputContainer .buttonWithAttachment img {
  height: auto;
  width: stretch;
}

.inputContainer .button svg,
.inputContainer .buttonError svg {
  color: #1d8dbd;
  font-size: 20px;
}

.inputContainer .buttonError {
  border-color: red;
  background-color: #ff00002f;
}

.inputContainer .buttonError svg {
  color: red;
}

.error {
  color: red;
  font-size: 0.8rem;
}
