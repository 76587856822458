.container textarea {
  resize: vertical;
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}

.hintIconContainer {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 14px;
}

.hintIconContainer h4 {
  margin-bottom: 0;
}

.hintIcon {
  margin-left: 4px;
  color: inherit;
}
