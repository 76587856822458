.container {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.container .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vh 0;
  width: 100vw;
}
