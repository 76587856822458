.container .button {
  background-color: var(--primary-color) !important;
  color: white !important;
  font-size: 1rem;
}

.partners {
  margin-bottom: 8px;
}

.partner {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid black;
  color: black;
  margin-bottom: 8px;
}

.partnerName {
  flex: 1;
}

.partnerName span:first-child {
  font-weight: bold;
  margin-right: 4px;
}

.partnerActions {
  display: flex;
  align-items: center;
  gap: 4px;
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}
